import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Typography from '@material-ui/core/Typography';


const ErrorPage = () => (
  <Layout>
    <SEO title="Error page" />
    <div className="content_container">
    <Typography className="page_header" component="h1" variant="h3" paragraph>
        Oops!
      </Typography>

      <Typography component="p" paragraph>
        Maybe something went wrong. Maybe we couldn't find the page you were looking for.
      </Typography>

      <Typography component="p" paragraph>
        Either way, you can return to our homepage by clicking <Link to="/">here</Link>.
      </Typography>
    </div>
  </Layout>
)

export default ErrorPage
